@import "bootstrap/dist/css/bootstrap.min.css";
/* @import "@fortawesome/fontawesome-free/css/all.min.css"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Noto Sans Lao", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-login {
  margin: 0;
  background-color: #ffffff;
  width: auto;
  height: 100vh;
  box-sizing: border-box;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("./picture/logoANS.png");
}

.a {
  text-decoration: none;
}

.wrapper {
  width: auto;
  /* background-color: #00000033; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-box {
  position: relative;
  width: 375px;
  height: auto;
  backdrop-filter: blur(10px);
  border: 2px solid #7a7a7a;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0px 0px 10px 2px #00000033;
}

@media (max-width: 600px) {
  .login-box {
    width: 80%;
  }
}

@media (min-width: 600px) {
  .container-login {
    background-size: 540px;
  }
}

.login-header {
  width: 140px;
  height: 50px;
  background-color: #d01e24;
  border-radius: 0 0 10px 10px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.login-header span {
  font-size: 20px;
  font-weight: bold;
}

.input-box {
  display: grid;
  position: relative;
  margin: 20px 0;
}

.input-field {
  height: 40px;
  width: 100%;
  background: transparent;
  border-radius: 30px;
  padding-inline: 20px 30px;
  border: solid 2px #7a7a7a;
  outline: none;
  font-size: 16px;
}

#user {
  margin-bottom: 10px;
}
.toggle-password{
  position: absolute;
  top: 5px;
  right: 10px;
  transition: 0.5s;
  cursor: text;
}

.label {
  position: absolute;
  top: 6px;
  left: 25px;
  transition: 0.5s;
  cursor: text;
}

.input-field:focus~.label,
.input-field:valid~.label {
  position: absolute;
  background-color: #7a7a7a;
  width: 100px;
  height: 20px;
  border-radius: 30px;
  top: -10px;
  left: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.remember-me {
  display: flex;
  gap: 4px;
}

.forgot a {
  color: black;
}

.input-submit-login {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.5s;
  background-color: #d01e24;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.register {
  text-align: center;
}

.register a {
  font-weight: 500;
  color: black;
}

.register a:hover {
  color: #d01e24;
}

.container-comission {
  background-color: #f7f8f9;
  height: 100%;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  justify-content: center;
}

.Home-bar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f7f8f9;
  border-radius: 10px;
  padding: 0 1rem;
}

.head-bar {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-bottom: solid 1px #c3cbd0;
  display: flex;
}

.head-search {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.head-profile {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 2rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  padding: 8px 0;
}

.dropdown-menu a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: #ddd;
}

.dropdown.show .dropdown-menu {
  display: block;
}

.content-commission {
  width: auto;
  height: auto;
}


@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

.oder-key {
  display: none;
}

.order-key-active {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  display: block;
}

.Revenue {
  display: none;
}

.Revenue-active {
  display: flex;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  display: block;
}

.COD {
  display: none;
}

.COD-active {
  /* order: -1; */
  display: block;
}

.menu-mobile {
  position: absolute;
  width: 100%;
  height: 63px;
  padding: 0 1rem;
  top: 0px;
  left: -130%;
  opacity: 0;
  transition: 0.7s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e11f28;
}

.menu-mobile-active {
  position: absolute;
  width: 100%;
  height: 63px;
  top: 0px;
  left: 0px;
  padding: 0 1rem;
  opacity: 1;
  transition: 1s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e11f28;
}

.pickerdate {
  position: absolute;
  height: 73px;
  width: auto;
  transition: 0.5s;
  font-size: 14px;
  opacity: 0;
  right: -100%;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.pickerdateActive {
  position: absolute;
  height: 73px;
  width: auto;
  transition: 0.5s;
  font-size: 10px;
  opacity: 1;
  right: 55px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputDate {
  border-radius: 5px;
  padding: 3px 5px;
  margin-right: 5px;
  border: solid 1px;
  background-color: #ffffff;
  color: black;
}

@media (max-width: 350px) {
  .inputDate {
    width: 102px;
  }
}

.head {
  font-size: 14px;
  font-weight: bold;
  color: black;
  padding: 0.5rem 0rem;
  justify-content: left;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 3px 0;
  color: red;
}

.left {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  padding: 3px 0;
}

.right {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  padding: 3px 0;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 3px 0;
}

.space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3px 0;
}

.icon-size-40 {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.icon-size-30 {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.icon-size-25 {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.align-center {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.monitor-box {
  /* background-color: #e11f28; */
  background-color: #e60000;
  border-Radius: 10px;
  padding: 1rem;
  width: auto;
  justify-content: center;
}

.button-success {
  font-size: 12px;
  background-color: green;
  border-radius: 5px;
  color: white;
  margin: 2px 0;
  border: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 5px 2px;
}

.Row-1 {
  margin: 0;
  padding: 10px 0;
  border-top: solid 2px #CCC8C8;
}

.font-bold-white-24 {
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.percent {
  padding: 5px 16px;
  font-weight: bold;
  background-color: #FFF;
  border-radius: 10px;
  width: fit-content;
}

.content {
  display: flex;
  width: 100%;
  height: auto;
  padding: 10px 0;
  border-bottom: solid 1px #CCC8C8;
  border-top: solid 1px #CCC8C8;
  justify-content: space-between;
  align-items: center;
}

.button-switch-content {
  border: none;
  background-color: #BFC0C1;
  border-radius: 10px;
  width: 110px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  color: black;
}

.button-switch-content-color {
  background-color: #e11f28 !important;
  cursor: pointer;
  color: white !important;
  border: none;
  border-radius: 10px;
  width: 110px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
}

.container-content {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  width: auto;
  background-color: #ffffff;
  padding: 1rem 0;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
}

.container-icon {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
}

.icon-content {
  height: 100%;
  width: auto;
  border: solid 5px #CCC8C8;
  border-radius: 50%;
  padding: 10px;
}

.value-green {
  display: flex;
    justify-content: center;
    flex-grow: 1;
    font-weight: bold;
    font-size: 14px;
    color: green;
}

.value-red {
  display: flex;
    justify-content: center;
    flex-grow: 1;
    font-weight: bold;
    font-size: 14px;
    color:#e11f28;
}